import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-columns-three-white.scss";
import {Network} from "../../../../../../../../network/Network";
import {Resources} from "../../../../../../../../resources/Resources";

export class WebsiteColumnsThreeWhiteComponent extends Component {

    public columnsContainer: Component;
    public columns: { name: string, picture: string, state: string }[];

    constructor(p: { title: string, columns: { name: string, picture: string, state: string }[] }) {
        super();

        this.columns = p.columns;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteColumnsThreeWhite}">
                <h2 class="${s.description}">
                    ${p.title}
                </h2>
                <div class="${s.columns}"></div>
            </div>
        `;
    }

    commit() {
        this.columnsContainer = this.el(s.columns);

        this.columns.forEach(column => {
            //language=HTML
            if (column.state == 'normal') {
                this.columnsContainer.append(`
                    <div class="${s.column}">
                        <div class="${s.text}">
                            ${column.name}
                        </div>
                        <div class="${s.icon}">
                            <img src="${Network.vendor}images/${column.picture}" alt="${Resources.t("alt.sedestralInterfacePageRepresenting")} ${column.name}." height="" width="272.66" height="347.53" />
                        </div>
                    </div>
                `);
            } else {
                this.columnsContainer.append(`
                    <div class="${s.column}">
                        <div class="${s.text}">
                            ${column.name}
                        </div>
                        <div class="${s.icon}">
                            <img class="${s.special}" src="${Network.vendor}images/${column.picture}" alt="${Resources.t("alt.sedestralInterfacePageRepresenting")} ${column.name}." width="122.7" height="122.7"/>
                        </div>
                    </div>
                `);
            }
        });

        super.commit();
    }
}