import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteContentLivechatComponent} from "../../content/pages/livechat/WebsiteContentLivechatComponent";
import {WebsitePageComponent} from "../WebsitePageComponent";


export class WebsiteLivechatComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentLivechatComponent();
    }
}