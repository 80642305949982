@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentWebsiteFooter {
  width: calc(100% - 40px);
  max-width: 1150px;
  padding: 0 20px;

  .brand {
    width: 100%;

    .name {
      margin-bottom: 4px;
      font-size: 30px;

      @include texts.font("extra-bold");
    }

    .capsuleContainer {
      width: fit-content;
    }
  }

  .columns {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: space-between;

    .column {
      width: fit-content;

      .title {
        font-size: 26px;

        @include texts.font("bold");


        .entries {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 0;

          .entry {
            font-size: 16px;
            cursor: pointer;

            @include texts.font("semi-bold");

            &:hover {
              text-decoration: underline;
            }

            &.blog &.inbox, &.mail, &.contacts, &.statistics, &.livechat, &.privacyPolicy, &.customerService, &.pricing, &.schedule, &.blog &.conditionsOfUse, &.generalSalesConditions, &.smartBlog {
              visibility: visible;
            }
          }
        }

        .language {
          position: relative;
          height: 40px;
          width: 130px;
          margin-top: 10px;

          div[data-selectbox] {
            height: 36px;
          }
        }
      }
    }
  }

  .copyright {
    margin-top: 50px;
    font-size: 14px;
    padding-bottom: 40px;

    @include texts.font("medium");
  }

  @media (max-width: 480px) {
    .columns {
      gap: 25px 105px;

      .column {
        .title {
          font-size: 25px;

          .entries {
            gap: 12px;
          }
        }
      }
    }

    .copyright {
      text-align: center;
    }
  }
}