import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteContentPricingComponent} from "../../content/pages/pricing/WebsiteContentPricingComponent";
import {WebsitePageComponent} from "../WebsitePageComponent";

export class WebsitePricingComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentPricingComponent();
    }
}