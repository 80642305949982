@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentSiteCreator {

  .title {
    font-size: 20px;
    position: relative;

    @include texts.font("bold");
  }

  .cases {
    margin-top: 25px;
  }
}