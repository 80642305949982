import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-brand.scss";
import {CapsuleComponent} from "../../../../../panel/components/components/capsule/CapsuleComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import {ProductName} from "../../../../../../models/product/ProductName";
import {Resources} from "../../../../../../resources/Resources";
import {
    txtCapitalize
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtCapitalize";

export class WebsiteBrandComponent extends Component {

    public productType: ProductType;
    public capsuleContainer: Component;

    constructor(productType: ProductType) {
        super();
        this.productType = productType;
        let style = "";
        switch (productType) {
            case ProductType.CLIENT_SERVICE:
                style = "display:none";
                break;
        }

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteBrand}">
                <div class="${s.capsuleContainer}" style="${style}"></div>
                <h1 class="${s.productName}">
                    ${this.productType == ProductType.CLIENT_SERVICE ? Resources.t("words.customerService")
            : Resources.t("products." + ProductName.toString(this.productType))}
                </h1>
            </div>
        `;
    }

    commit() {
        this.capsuleContainer = this.el(s.capsuleContainer);
        this.capsuleContainer.render(new CapsuleComponent(this.productType));

        super.commit();
    }
}