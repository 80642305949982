import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-presentation-degraded.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsitePresentationDegradedComponent extends Component {

    public color: string;
    public size: string;
    public titles: { title: string }[];
    public index: number;
    public smallContainer: Component;
    public mediumContainer: Component;
    public bigContainer: Component;

    constructor(p: { titles: { title: string }[], image: string, color: string, size: string }) {
        super();

        this.color = p.color;
        this.size = p.size;
        this.titles = p.titles;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentationDegraded}">
                <div class="${s.head} ${s[this.color]}">
                    <h2 class="${s.title} ${s.small}"></h2>
                    <h2 class="${s.title} ${s.medium}"></h2>
                    <h2 class="${s.title} ${s.big}"></h2>
                </div>
                <div class="${s.imageContainer}">
                    <img class="${s.image} ${s[this.size]}" src="${Network.vendor}images/${p.image}"
                         alt="${Resources.t("alt.extractSedestralInterface")}"/>
                </div>
            </div>
        `;
    }

    commit() {
        this.smallContainer = this.el(s.small);
        this.mediumContainer = this.el(s.medium);
        this.bigContainer = this.el(s.big);
        this.index = 0;
        this.titles.forEach(title => {
            //language=HTML
            if (this.index == 0) {
                this.smallContainer.append(`
                    ${title.title}
                `);
            } else if (this.index == 1) {
                this.mediumContainer.append(`
                    ${title.title}
                `);
            } else if (this.index == 2) {
                this.bigContainer.append(`
                    ${title.title}
                `);
            }
            this.index += 1;
        });
        super.commit();
    }
}