import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-presentation.scss";
import {WebsiteImageSkewedComponent} from "../image/skewed/WebsiteImageSkewedComponent";
import {Network} from "../../../../../../network/Network";
import {Resources} from "../../../../../../resources/Resources";

export class WebsitePresentationComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentation}">
                <div class="${s.left}">
                    <h2 class="${s.title}">
                        ${Resources.t("sentences.optimizeCustomerRelationship")}
                    </h2>
                    <p class="${s.description}">
                        ${Resources.t("sentences.sedestralSolutionInnovation")}
                    </p>
                    <a class="${s.startButton}" href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                        ${Resources.t("words.begin")}
                        <i class="${s.icon}"></i>
                    </a>
                </div>
                <div class="${s.right}">
                    ${this.draw(new WebsiteImageSkewedComponent({
                        link: `${Network.vendor}images/capture-blog.webp`,
                        shadow: "intermediate",
                        width: `634.5`,
                        height: `356.91`
                    }))}
                </div>
            </div>
        `;
    }
}