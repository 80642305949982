import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./website-content-statistics.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {Network} from "../../../../../../../network/Network";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsiteContentStatisticsComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentStatistics}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: Resources.t("sentences.yourPerformanceImpact"),
                            text: Resources.t("sentences.evaluatingYourImpact"),
                            productType: ProductType.STATISTICS,
                            url: `${Network.vendor}images/capture-statistics.webp`
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("sentences.centralizeStatistics"),
                            description: "",
                            image: "illus-applications.svg",
                            color: "black",
                            size: "intermediate"
                        }))}
                    </div>
                    <div class="${r.reportsContainer}">
                        <div class="${r.reports}">
                            <div class="${r.right}">
                                <img class="${r.image}" src="${Network.vendor}images/illus-filter.webp"
                                     alt="${Resources.t("alt.creationPresentationReports")}" draggable="false"
                                     width="380" height="347.51"
                                />
                            </div>
                            <div class="${r.left}">
                                <h2 class="${r.title}">
                                    ${Resources.t("sentences.createReports")}
                                </h2>
                                <p class="${r.subtitle}">
                                    ${Resources.t("sentences.filtersStatistics")}
                                </p>
                                <img class="${r.line}" src="${Network.vendor}images/separator-line.svg"
                                     alt="${Resources.t("alt.separationLine")}" draggable="false"/>
                            </div>
                        </div>
                        <div class="${r.container}">
                            ${this.draw(new WebsitePresentationVerticalComponent({
                                title: Resources.t("sentences.evaluatedCollaborator"),
                                description: "",
                                image: "capture-collaboraters.webp",
                                color: "black",
                                size: "tall",
                                border: "border"
                            }))}
                        </div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsGridWhiteComponent({
                            title: Resources.t("sentences.mainReports"),
                            color: "blue",
                            details: [{
                                icon: "black-lineal-conversations",
                                title: Resources.t("sentences.numberConversations"),
                                subtitle: Resources.t("sentences.contactHours")
                            }, {
                                icon: "black-lineal-planning",
                                title: Resources.t("sentences.conversationsHours"),
                                subtitle: Resources.t("sentences.contactHours")
                            }, {
                                icon: "black-lineal-delay",
                                title: Resources.t("sentences.responseTime"),
                                subtitle: Resources.t("sentences.contactHours")
                            }, {
                                icon: "black-lineal-rating",
                                title: Resources.t("words.ratings"),
                                subtitle: Resources.t("sentences.contactHours")
                            }, {
                                icon: "black-lineal-team",
                                title: Resources.t("words.employees"),
                                subtitle: Resources.t("sentences.contactHours")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("sentences.questions.statistics")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}