import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentBlogWritingComponent} from "../../content/pages/blog/WebsiteContentBlogWritingComponent";


export class WebsiteBlogWritingComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentBlogWritingComponent();
    }
}