import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "../statistics/website-content-statistics.scss";
import {WebsiteProductComponent} from "../../product/WebsiteProductComponent";
import {WebsiteSliderLeftComponent} from "../../slider/left/WebsiteSliderLeftComponent";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {Network} from "../../../../../../../network/Network";
import {
    WebsiteIssuesComponent
} from "../../issues/WebsiteIssuesComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteColumnsGridWhiteComponent} from "../../columns/grid/white/WebsiteColumnsGridWhiteComponent";
import {WebsitePresentationLightComponent} from "../../presentation/light/WebsitePresentationLightComponent";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";

export class WebsiteContentBlogWritingComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentStatistics}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsiteProductComponent({
                            title: "Générez du contenu optimisé pour le SEO",
                            text: "Outil de rédaction d'articles avec IA, il génère des idées, structure vos contenus et propose des images pertinentes. Que vous automatisiez la rédaction ou cherchiez de l'aide, cet outil optimise votre création et améliore votre productivité.",
                            productType: ProductType.BLOG,
                            url: `${Network.vendor}images/capture-blog-writing.webp`,
                            promo: "🚀 +150 Rédacteurs et Agences SEO utilisent notre IA chaque jour"
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteSliderLeftComponent(Resources.t("words.howItWorks"),
                                [{
                                    icon: "draft",
                                    title: Resources.t("words.instructionsTitle"),
                                    desc: Resources.t("words.instructionsDescription"),
                                    image: "capture-blog-rules.webp"
                                }, {
                                    icon: "paragraph",
                                    title: Resources.t("words.proposalsTitle"),
                                    desc: Resources.t("words.proposalsDescription"),
                                    image: "capture-blog-content-proposals.webp",
                                }, {
                                    icon: "section",
                                    title: Resources.t("words.planTitle"),
                                    desc: Resources.t("words.planDescription"),
                                    image: "capture-blog-plan.webp",
                                }, {
                                    icon: "pensil",
                                    title: Resources.t("words.writingTitle"),
                                    desc: Resources.t("words.writingDescription"),
                                    image: "capture-blog-write.webp",
                                }]
                        ))}
                    </div>
                    <div class="${r.reportsContainer}">
                        <div class="${r.reports}">
                            <div class="${r.right}">
                                <img class="${r.image}" src="${Network.vendor}images/illus-filter.webp"
                                     alt="${Resources.t("alt.creationPresentationReports")}" draggable="false"
                                     width="380" height="347.51"
                                />
                            </div>
                            <div class="${r.left}">
                                <h2 class="${r.title}">
                                    ${Resources.t("sentences.createReports")}
                                </h2>
                                <p class="${r.subtitle}">
                                    ${Resources.t("sentences.filtersStatistics")}
                                </p>
                                <img class="${r.line}" src="${Network.vendor}images/separator-line.svg"
                                     alt="${Resources.t("alt.separationLine")}" draggable="false"/>
                            </div>
                        </div>
                        <div class="${r.container}">
                            ${this.draw(new WebsitePresentationVerticalComponent({
                                title: Resources.t("sentences.evaluatedCollaborator"),
                                description: "",
                                image: "capture-collaboraters.webp",
                                color: "black",
                                size: "tall",
                                border: "border"
                            }))}
                        </div>
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationLightComponent({
                            title: Resources.t("words.seoOptimizedBlog"),
                            subtitle: Resources.t("words.seoOptimizedBlogSubtitle"),
                            image: "capture-blog-speed",
                            size: "medium",
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteColumnsGridWhiteComponent({
                            title: Resources.t("words.otherFeatures"),
                            color: "blue",
                            details: [{
                                icon: "black-lineal-domains",
                                title: Resources.t("words.domainNameTitle"),
                                subtitle: Resources.t("words.domainNameSubtitle")
                            }, {
                                icon: "black-export",
                                title: Resources.t("words.exportArticlesTitle"),
                                subtitle: Resources.t("words.exportArticlesSubtitle")
                            }, {
                                icon: "black-lineal-delay",
                                title: Resources.t("words.scheduleArticlesTitle"),
                                subtitle: Resources.t("words.scheduleArticlesSubtitle")
                            }]
                        }))}
                    </div>
                    <div class="${s.container}">
                        ${this.draw(new WebsiteIssuesComponent({
                            questions: Resources.t("sentences.questions.smartblog")
                        }))}
                    </div>
                </div>
            </div>
        `;
    }
}