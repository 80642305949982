@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentWebsitePresentationCapsule {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;

  .description {
    width: 70%;
    font-size: 44px;
    text-align: center;
    background: var(--grad-blog);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include texts.font("bold");
  }

  .icon {
    width: 60px;
    height: 60px;

    @include icons.ico("icon-statistics");
  }

  .columns {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 43px;

    .column {
      width: 33.33%;

      img {
        width: 100%;
        height: auto;
        border-radius: 11px;
        box-shadow: 0 0 51.34px var(--black-alpha-middle);
      }
    }

  }

  .image {
    position: relative;
    width: 90%;
    margin-top: -100px;

    img {
      width: 100%;
      border-radius: 13px;
      box-shadow: 0 0 51.34px var(--black-alpha-middle);
    }
  }

  @media (max-width: 480px) {
    text-align: center;

    .description {
      width: 100%;
      font-size: 25px;
      margin-left: 0;
    }

    .columns {
      display: grid;
      gap: 0;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
      margin-top: 20px;

      .column {
        width: calc(100% - 40px);
        height: fit-content;
        padding: 20px;
        background: var(--grey-sur);
        border-radius: 33px;
      }

      .column:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 5;
      }

      .column:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 4;
      }

      .column:nth-child(3) {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 6;
      }
    }

    .image {
      margin: 0;
      width: 100%;
    }
  }
}