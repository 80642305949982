import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentStatisticsComponent} from "../../content/pages/statistics/WebsiteContentStatisticsComponent";

export class WebsiteStatisticsComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentStatisticsComponent();
    }
}