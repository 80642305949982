import {
    PageComponent
} from "../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import * as l from "../../../panel/components/pages/page.scss";
import {WebsiteHomeComponent} from "../components/pages/models/WebsiteHomeComponent";

export class WebsiteHomePage extends PageComponent {

    constructor() {
        super();
        this.updateMetas("home");
        this.status.online = undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${l.page}">
                ${this.draw(new WebsiteHomeComponent())}
            </div>
        `;
    }

    onHash(): void {

    }
}