import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentHelpCenterComponent} from "../../content/pages/helpcenter/WebsiteContentHelpCenterComponent";

export class WebsiteHelpcenterComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentHelpCenterComponent();
    }
}