import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentPolicyComponent} from "../../content/pages/policy/WebsiteContentPolicyComponent";

export class WebsitePolicyComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentPolicyComponent();
    }
}