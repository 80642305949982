import {
    VisualModalComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {SiteCreatorComponent} from "../SiteCreatorComponent";

export class SiteCreatorModalComponent extends VisualModalComponent {
    constructor() {
        super();
    }

    commit() {
        super.commit();

        this.headContainer.remove();
        this.contentContainer.clearAll();
        this.contentContainer.render(new SiteCreatorComponent());
    }
}