import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentHomeComponent} from "../../content/pages/home/WebsiteContentHomeComponent";


export class WebsiteHomeComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentHomeComponent();
    }
}