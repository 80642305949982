import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {
    WebsiteContentCustomerServiceComponent
} from "../../content/pages/customerService/WebsiteContentCustomerServiceComponent";

export class WebsiteCustomerServiceComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentCustomerServiceComponent();
    }
}
