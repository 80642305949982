import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentCguComponent} from "../../content/pages/cgu/WebsiteContentCguComponent";

export class WebsiteCguComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentCguComponent();
    }
}