import {
    PageComponent
} from "../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import {SiteNoneComponent} from "../components/site/none/SiteNoneComponent";
import {RoleNoneComponent} from "../components/role/none/RoleNoneComponent";
import * as l from "./page.scss";
import {OfferTemplateIconComponent} from "../components/offer/template/icon/OfferTemplateIconComponent";
import {OfferModalComponent} from "../components/offer/modal/OfferModalComponent";
import {ProductType} from "../../../../models/product/ProductType";
import {EntityService} from "../../../../services/entity/EntityService";
import {OfferProductLevel} from "../../../../models/offer/product/OfferProductLevel";
import {Resources} from "../../../../resources/Resources";

export abstract class PanelPageComponent extends PageComponent {
    renderNoneSite() {
        this.template = this.draw(new SiteNoneComponent());
    }

    renderNoneRole() {
        this.template = this.draw(new RoleNoneComponent());
    }

    renderOffer(productType: ProductType) {
        let offerProduct = EntityService.activeOfferProducts[productType];
        if (!offerProduct || offerProduct.level == OfferProductLevel.FREE) {
            //language=HTML
            let offerContainer = this.prepend(`
                <div class="${l.offer}">
                    <div class="${l.left}">
                        <div class="${l.iconContainer}">
                            ${this.draw(new OfferTemplateIconComponent(offerProduct.template.type))}
                        </div>
                        <div class="${l.name}">
                            ${Resources.t("words.offerFree")}
                        </div>
                        <div class="${l.price}">
                            ${Resources.t("words.free")}
                        </div>
                    </div>
                    <div class="${l.right}">
                        <div class="${l.text}">
                            ${Resources.t("words.offerSubText")}
                        </div>
                        <div class="${l.button}">
                            ${Resources.t("words.offerViewOffers")}
                        </div>
                    </div>
                </div>
            `);

            offerContainer.onClick(() => {
                let modal = new OfferModalComponent(productType);
                modal.screenRender();
            });

            this.el(l.content).addClass(l.free);
        }
    }
}