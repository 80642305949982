import * as s from "./website-head-submenu-content.scss";
import {WebsiteHeadSubmenuModelsProductsComponent} from "../models/WebsiteHeadSubmenuModelsProductsComponent";
import {WebsiteHeadSubmenuModelsUseCaseComponent} from "../models/WebsiteHeadSubmenuModelsUseCaseComponent";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {Network} from "../../../../../../../network/Network";
import {WebsiteHeadSubmenuModelsBlogComponent} from "../models/WebsiteHeadSubmenuModelsBlogComponent";
import {WebsiteHeadSubmenuContentScheduleComponent} from "./WebsiteHeadSubmenuContentScheduleComponent";
import {WebsiteHeadSubmenuModelsScheduleComponent} from "../models/WebsiteHeadSubmenuModelsScheduleComponent";
import {WebsiteHeadSubmenuModelsPricingComponent} from "../models/WebsiteHeadSubmenuModelsPricingComponent";

export class WebsiteHeadSubmenuContentMobileComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenuContent}">
                <a href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                    <div class="${s.login}">
                        ${Resources.t("words.signUp")}
                    </div>
                </a>
                ${this.draw(new WebsiteHeadSubmenuModelsProductsComponent())}
                ${this.draw(new WebsiteHeadSubmenuModelsPricingComponent())}
                <!--${this.draw(new WebsiteHeadSubmenuModelsUseCaseComponent())}-->
                ${this.draw(new WebsiteHeadSubmenuModelsScheduleComponent())}
                ${this.draw(new WebsiteHeadSubmenuModelsBlogComponent())}
            </div>`;
    }

    commit() {
        this.els(s.column).forEach(column => {
            column.el(s.title).onClick(() => {
                if (column.el(s.cases).isDisplayNone()) {
                    this.openColumn(column);
                } else {
                    this.closeColumn(column);
                }
            });
        });

        super.scrollable();
        super.commit();
    }

    onOpen() {
        let columns = this.els(s.column);
        let openedColumn;
        columns.forEach(column => {
            this.closeColumn(column);

            let casesContainer = column.el(s.cases);
            let cases = casesContainer.els(s.case);
            cases?.forEach(caseElem => {
                if (caseElem.getHTMLElement().parentElement && caseElem.getHTMLElement().parentElement['href'] == window.location.href) {
                    openedColumn = column;
                    this.openColumn(column);
                }
            });
        });

        if (!openedColumn) {
            this.openColumn(columns[0]);
        }


    }
}