import {
    PageComponent
} from "../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import * as l from "../../../panel/components/pages/page.scss";
import {WebsiteHelpcenterComponent} from "../components/pages/models/WebsiteHelpcenterComponent";

export class WebsiteHelpcenterPage extends PageComponent {
    constructor() {
        super();
        this.updateMetas("helpcenter");
        this.status.online = undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${l.page}">
                ${this.draw(new WebsiteHelpcenterComponent())}
            </div>
        `;
    }

    onHash(): void {

    }
}