import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentPublisherComponent} from "../../content/pages/publisher/WebsiteContentPublisherComponent";

export class WebsitePublisherComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentPublisherComponent();
    }
}