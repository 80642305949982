import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-product.scss";
import {WebsiteBrandComponent} from "../brand/WebsiteBrandComponent";
import {WebsiteImageSkewedComponent} from "../image/skewed/WebsiteImageSkewedComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import {ProductName} from "../../../../../../models/product/ProductName";

export class WebsiteProductComponent extends Component {

    public productType: ProductType;

    constructor(p: { title: string, text: string, productType: ProductType, url: string, promo?: string }) {
        super();

        this.productType = p.productType;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteProduct}">
                <div class="${s.brandContainer}">
                    ${this.draw(new WebsiteBrandComponent(p.productType))}
                </div>
                <div class="${s.imageContainer}">
                    ${this.draw(new WebsiteImageSkewedComponent({
                        link: p.url,
                        shadow: "important",
                        width: `920`,
                        height: `517.5`
                    }))}
                </div>
                <div class="${s.cardTexts}">
                    <h2 class="${s.title} ${s[ProductName.toString(this.productType)]}">
                        ${p.title}
                    </h2>
                    <p class="${s.text}">
                        ${p.text}
                    </p>
                    ${p.promo ? `<div class="${s.promo}">${p.promo}</div>` : ``}
                </div>
            </div>
        `;
    }
}