import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "../website-content.scss";
import * as r from "./schedule-content-statistics.scss";
import {WebsitePresentationVerticalComponent} from "../../presentation/vertical/WebsitePresentationVerticalComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {
    loadScript
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/Script";
import {
    LoaderLightComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {WebsiteIssuesComponent} from "../../issues/WebsiteIssuesComponent";

export class WebsiteContentScheduleComponent extends Component {

    public loaderLightComponent = new LoaderLightComponent();

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteContent} ${r.componentWebsiteContentSchedule}">
                <div class="${s.content}">
                    <div class="${s.container}">
                        ${this.draw(new WebsitePresentationVerticalComponent({
                            title: Resources.t("sentences.schedule"),
                            description: "",
                            color: "black",
                            size: "intermediate"
                        }))}
                        <div class="calendly-inline-widget ${r.module}" data-url="https://calendly.com/contact-xa2v/30min">
                            ${this.draw(this.loaderLightComponent)}
                        </div>
                    </div>
                </div>
            </div>
            <div class="${s.container}">
                ${this.draw(new WebsiteIssuesComponent({
                    questions: Resources.t("sentences.questions.schedule")
                }))}
            </div>
        `;
    }

    commit() {
        super.commit();

        let moduleContainer = this.el(r.module);

        loadScript("https://assets.calendly.com/assets/external/widget.js", () => {
            const iframe = moduleContainer.getHTMLElement().querySelector("iframe");

            iframe.onload = () => {
                this.timeOut(() => {
                    this.loaderLightComponent.remove();
                }, 500);
            }
        });
    }
}