import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-join.scss";
import {Resources} from "../../../../../resources/Resources";
import {Network} from "../../../../../network/Network";

export class WebsiteJoinComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebSiteStartFree}">
                <h2 class="${s.title}">
                    ${Resources.t("sentences.startUsingSedestralFree")}
                </h2>
               
                    <a class="${s.button}" href="${Network.router.pages.getPanelUrl('signup')}" target="_blank">
                        ${Resources.t("words.signUp")}
                    </a>
              
                <!--<h3 class="${s.noCard}">
                        *${Resources.t("sentences.noCreditCard")}
                </h3>-->
            </div>
        `;
    }
}