import * as s from "./site-creator.scss";
import {
    StepsComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/steps/StepsComponent";
import {SiteCreatorCreateComponent} from "./create/SiteCreatorCreateComponent";
import {SiteCreatorJoinComponent} from "./join/SiteCreatorJoinComponent";
import {Resources} from "../../../../../../resources/Resources";
import {
    CasesComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/cases/CasesComponent";

export class SiteCreatorComponent extends StepsComponent {
    constructor() {
        super();
    }

    initButtons() {
        this.initButton("create", () => new SiteCreatorCreateComponent(), Resources.t("words.createSite"));
        this.initButton("invitation", () => new SiteCreatorJoinComponent(), Resources.t("words.joinSite"));
    }

    initBase() {
        //language=HTML
        this.append(`
            <div class="${s.componentSiteCreator}">
                <div class="${s.title}">
                    🏛 ${Resources.t("words.addWorkspace")}
                </div>
                <div class="${s.cases}">
                    ${this.draw(new CasesComponent([
            {name: Resources.t("words.new"), id: "create"},
            {name: Resources.t("words.invitation"), id: "invitation"}
        ]))}
                </div>
            </div>
        `);
    }
}