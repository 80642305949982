import {
    PageComponent
} from "../../../../sedestral-interface-modules/sedestral-interface-router/pages/types/PageComponent";
import * as l from "../../../panel/components/pages/page.scss";
import {WebsiteScheduleComponent} from "../components/pages/models/WebsiteScheduleComponent";

export class WebsiteSchedulePage extends PageComponent {

    constructor() {
        super();
        this.updateMetas("schedule");
        this.status.online = undefined;
    }

    init(): void {
        //language=HTML
        this.template = `
            <div class="${l.page}">
                ${this.draw(new WebsiteScheduleComponent())}
            </div>
        `;
    }

    onHash(): void {

    }
}