import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {WebsiteContentLivechatComponent} from "../../content/pages/livechat/WebsiteContentLivechatComponent";
import {WebsitePageComponent} from "../WebsitePageComponent";
import {WebsiteContentBlogComponent} from "../../content/pages/blog/WebsiteContentBlogComponent";


export class WebsiteBlogComponent extends WebsitePageComponent {
    contentComponent(): Component {
        return new WebsiteContentBlogComponent();
    }
}