import * as s from "./website-head-submenu-content.scss";
import {ProductType} from "../../../../../../../models/product/ProductType";
import {CapsuleComponent} from "../../../../../../panel/components/components/capsule/CapsuleComponent";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";
import {WebsiteHeadSubmenuContentComponent} from "./WebsiteHeadSubmenuContentComponent";

export class WebsiteHeadSubmenuContentProductsComponent extends WebsiteHeadSubmenuContentComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentWebsiteHeadSubmenuContent}">
                <div class="${s.column}">
                    <!--
                    <h6 class="${s.title}">
                        ${Resources.t("words.communications")}

                        <span class="${s.arrow}"></span>
                    </h6>
                    -->
                    <div class="${s.cases}">
                            <a itemprop="url" href="${Network.router.pages.getUrlWithPath('smartblog')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.BLOG))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("products.blog")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("sentences.blogTitle")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <!--
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('inbox')}">
                            <div class="${s.case} ${s.inbox}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.INBOX))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("products.inbox")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("sentences.omnichannelInbox")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('livechat')}">
                            <div class="${s.case} ${s.livechat}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.LIVECHAT))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("products.livechat")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("sentences.simplifyYourRelationship")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('mail')}">
                            <div class="${s.case} ${s.mail}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.MAILS))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("products.mails")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("sentences.manageMails")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        -->
                    </div>
                </div>
<!--
                <div class="${s.column}">
                    <h6 class="${s.title}">
                        ${Resources.t("words.clientReport")}
                        <span class="${s.arrow}"></span>
                    </h6>
                    <div class="${s.cases}">
                        <a itemprop="url" href="${Network.router.pages.getUrlWithPath('statistics')}">
                            <div class="${s.case} ${s.statistics}">
                                <div class="${s.capsuleContainer}">
                                    ${this.draw(new CapsuleComponent(ProductType.STATISTICS))}
                                </div>
                                <div class="${s.texts}">
                                    <p itemprop="name" class="${s.caseTitle}">
                                        ${Resources.t("products.statistics")}
                                    </p>
                                    <p class="${s.description}">
                                        ${Resources.t("sentences.overviewOfPerformance")}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <div class="${s.case} ${s.helpcenter}">
                            <div class="${s.capsuleContainer}">
                                ${this.draw(new CapsuleComponent(ProductType.HELPCENTER))}
                            </div>
                            <div class="${s.texts}">
                                <p itemprop="name" class="${s.caseTitle}">
                                    ${Resources.t("products.helpCenter")}
                                </p>
                                <p class="${s.description}">
                                    ${Resources.t("sentences.importantInformationAccessible")}
                                </p>
                            </div>
                        </div>
                        <div class="${s.case} ${s.contacts}">
                            <div class="${s.capsuleContainer}">
                                ${this.draw(new CapsuleComponent(ProductType.CONTACTS))}
                            </div>
                            <div class="${s.texts}">
                                <p itemprop="name" class="${s.caseTitle}">
                                    ${Resources.t("products.contacts")}
                                </p>
                                <p class="${s.description}">
                                    ${Resources.t("sentences.consolidateCustomerData")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                -->
            </div>
        `;
    }
}