import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./website-presentation-vertical.scss";
import {Network} from "../../../../../../../network/Network";
import {Resources} from "../../../../../../../resources/Resources";

export class WebsitePresentationVerticalComponent extends Component {

    public color: string;
    public size: string;
    public shadow: string;
    public border: string;

    constructor(p: {
        title: string,
        description: string,
        image?: string,
        color: string,
        size: string,
        shadow?: string,
        border?: string
    }) {
        super();

        this.color = p.color;
        this.size = p.size;
        this.shadow = p.shadow;
        this.border = p.border;

        //language=HTML
        this.template = `
            <div class="${s.componentWebsitePresentationVertical}">
                <div class="${s.head}">
                    <h2 class="${s.title} ${s[this.color]}">
                        ${p.title}
                    </h2>
                    <p class="${s.description}">
                        ${p.description}
                    </p>
                </div>
                ${p.image ? `
                <div class="${s.imageContainer}">
                    <img class="${s.image} ${s[this.size]} ${s[this.shadow]} ${s[this.border]}"
                         src="${Network.vendor}images/${p.image}"
                         alt="${Resources.t("alt.sedestralInterfacePageRepresenting")} : ${p.title}."/>
                </div>` : ""}
            </div>
        `;
    }
}