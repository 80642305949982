.componentWebsiteContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    max-width: 1150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 100px;

    .container {
      width: 100%;
    }
  }

  @media (max-width: 750px) {
    .content {
      gap: 60px;
    }
  }
}